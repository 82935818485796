@import "@mf-ide/theme/default-theme";

html,body {
  margin:0;
  font-family: var(--pl-font-family);
  font-size: 14px;
}

input, select {
  font-family: var(--pl-font-family);
}